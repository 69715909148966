<template>
  <v-container fluid>
    <Loading :loading="!loaded" />
    <div v-if="loaded">
      <v-row>
        <v-col>
          <v-card-title
            primary-title
            class="text-lg-h4"
          >
            {{ t('admin.responsibilityrole.title') }}
            <!-- <div class="flex-grow-1"></div><DeliveryCreate @saved="search" :listButton="true" /> -->
          </v-card-title>
          <v-form>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <div>
                  <v-text-field
                    v-model="model.query.textSearch"
                    autofocus
                    :label="t('search.textSearch')"
                    @input="search"
                  />
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          align="left"
          justify="baseline"
        >
          <span class="font-italic font-weight-thin">{{ $tc('admin.responsibilityrole.resultsFound', model.data.responsibilities.length) }}</span>
        </v-col>
        <v-col
          align="right"
          cols="6"
        >
          <!-- <div  style="position: relative; height: 10px; z-index: 1000;">
            <DeliveryCreate @saved="search" :listButton="true" />
          </div> -->
        </v-col>
      </v-row>

      <v-divider />

      <div v-if="model.data">
        <v-simple-table
          dense
          height="90vh"
        >
          <template #default>
            <thead>
              <tr>
                <th>{{ t('admin.responsibilityrole.tableheader.title') }}</th>
                <th>{{ t('admin.responsibilityrole.tableheader.isAdmin') }}</th>
                <th>{{ t('admin.responsibilityrole.tableheader.deliveryDefaultSortOrder') }}</th>
                <th>{{ t('admin.responsibilityrole.tableheader.permissions') }}</th>
                <th>{{ t('admin.responsibilityrole.tableheader.approvalTypesInitiator') }}</th>
                <th>{{ t('admin.responsibilityrole.tableheader.approvalTypesApprover') }}</th>
                
                <th
                  v-for="roleCategory in model.data.roleCategories"
                  :key="roleCategory.id"
                  :colspan="roleCategory.roles.length"
                >
                  {{ roleCategory.title }}
                </th>
              </tr>
              <tr>
                <th />
                <th
                  colspan="5"
                  rowspan="2"
                />
                <template v-for="roleCategory in model.data.roleCategories">
                  <th
                    v-for="(role, rIndex) in roleCategory.roles"
                    :key="role.id"
                    :style="rIndex==0 ? 'border-left: 2px solid black;' : null "
                  >
                    {{ role.title }}
                  </th>
                </template>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="responsibility in model.data.responsibilities"
                :key="responsibility.id"
              >
                <th class="nowrap">
                  {{ responsibility.title }}
                </th>
                <td class="nowrap">
                  {{ responsibility.isAdmin }}
                </td>
                <td class="nowrap">
                  {{ responsibility.deliveryDefaultSortOrder }}
                </td>
                <td>{{ responsibility.permissionsString }}</td>
                <td>{{ responsibility.approvalTypesInitiatorString }}</td>
                <td>{{ responsibility.approvalTypesApproverString }}</td>

                <template v-for="roleCategory in responsibility.roleCategories">
                  <td
                    v-for="(role, rIndex) in roleCategory.roles"
                    :key="role.id"
                    :style="rIndex==0 ? 'border-left: 2px solid black;' : null "
                  >
                    <ResponsibilityRoleEdit
                      :id="role.membership ? role.membership.id : null"
                      :responsibility-id="responsibility.id"
                      :role-id="role.id"
                      @saved="search"
                    />
                  </td>
                </template>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
  </v-container>
</template>

<script>

var searchTimeout = null;
import Loading from '@/components/Loading.vue'
import ResponsibilityRoleEdit from '@/components/ResponsibilityRoleEdit.vue'

export default {
  
  name: 'ResponsibilityRole',
  components: { Loading, ResponsibilityRoleEdit },
  data() {
    return {
      model: Object,
      loaded: false,
      t: this.$t
    }
  },
  created() {
    let vm = this;
    vm.$AuthService.api.get('organization/initresponsibilityrolelist')
      .then(response => {
        vm.model = response.data;
        vm.loaded = true;
      });
  },
  methods: {
    search() {
      let vm = this;

      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(function () {
        vm.$AuthService.api.post('organization/responsibilityrolelist', vm.model.query)
        .then(response => {
          vm.model.data = response.data.data;
          vm.loaded = true;
        });
      }, 450);
    }
  }
}
</script>

<style scoped>
td.nowrap {
    white-space: nowrap;
}

table {
  white-space: nowrap;
  border: none;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  min-width: 100%;
}

table thead {
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
}

table tbody td,
table tbody th {
  /* border-top: 1px solid #ddd; */
  padding: 0.5rem;
}

table td {
  background: #fff;
  padding: .5rem;
}

table thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  padding: .5rem;
  background: white;
}

table tbody th {
  position: sticky;
  left: 0;
  background: white;
  text-align: left;
  z-index: 1;
  font-weight: normal;
}

[role="region"] {
  width: 100%;
  overflow: auto;
}
</style>